
    import { Component, Prop, mixins } from 'nuxt-property-decorator';
    import ModelsImages from '@/components/models/ModelsImages.vue';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import { PageComponent } from '@/store/page';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import BaseTabs from '@/components/blocks/base/BaseTabs.vue';
    import components from '@/components';

    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export interface BlockTab {
        title: string;
        component: {
            path: string;
            name: string;
            options: string;
        };
        data: PageComponent;
    }

    export const meta = {
        title: 'Вкладки с блоками',
        options: {
            tabs: {
                type: AvailableTypes.array,
                title: 'Табы',
                item: {
                    component: {
                        type: AvailableTypes.fullComponentsList
                    },
                    title: {
                        type: AvailableTypes.string,
                        title: 'Заголовок'
                    }
                }
            }
        },
        group: AvailableGroups.Вкладки
    };

    @Component({
        components: { BaseTabs, OptionsFieldsInlineEditor, ModelsImages, ...components }
    })
    export default class TabsWithBlocks extends mixins(PageBlockMixin) {
        @Prop() tabs: BlockTab[];
        @Prop() test;
        @Prop() component: PageComponent;
        @Prop() index;
    }
