
    import { Component, Prop } from 'nuxt-property-decorator';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import InlineSlider from '@/components/blocks/InlineSlider.vue';
    import SliderWrapper from '@/components/blocks/SliderWrapper.vue';
    import RelatedSliders from '@/components/blocks/RelatedSliders.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import swipe from '@/directives/swipe';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import SliderNavBtns from '@/components/common/SliderNavBtns.vue';
    import SliderBullets from '@/components/common/SliderBullets.vue';

    export interface IImage {
        src: string;
        alt: string;
        title: string;
        active: boolean;
    }

    export const meta: ComponentMeta = {
        title: 'Карусель с превью',
        options: {
            head: {
                title: 'Заголовок',
                type: AvailableTypes.string
            },
            images: {
                type: AvailableTypes.arrayOfType,
                title: 'Изображения',
                item: {
                    type: AvailableTypes.image
                }
            }
        },
        group: AvailableGroups.Галерея
    };

    @Component({
        components: {
            ImageComponent,
            OptionsFieldsInlineEditor,
            RelatedSliders,
            InlineSlider,
            SliderWrapper,
            SliderNavBtns,
            SliderBullets
        },
        directives: { swipe }
    })
    export default class SliderExampleWork extends PageBlockMixin {
        @Prop() images;
        @Prop() buttonText?;
        @Prop() buttonLink;
        @Prop() head;
        @Prop() modal;
        @Prop() activePhotoIndex;
        @Prop() component;
    }
