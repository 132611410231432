
    import { Component, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import Button from '@/components/blocks/Button.vue';
    import BaseTabs from '@/components/blocks/base/BaseTabs.vue';
    import SliderWrapper from '@/components/blocks/SliderWrapper.vue';
    import SliderNavBtns from '@/components/common/SliderNavBtns.vue';
    import SliderBullets from '@/components/common/SliderBullets.vue';
    import swipe from '@/directives/swipe';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';

    export const meta: ComponentMeta = {
        title: 'Вкладки с списком, текстом и слайдером',
        options: {
            head: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                default: 'Для кого мы это делаем'
            },
            tabs: {
                title: 'Вкладки',
                type: AvailableTypes.array,
                item: {
                    tabTitle: {
                        type: AvailableTypes.string,
                        title: 'Заголовок вкладки'
                    },
                    sliderImg: {
                        title: 'Картинки для слайдера',
                        type: AvailableTypes.array,
                        item: {
                            img: {
                                type: AvailableTypes.image,
                                title: 'Картинка'
                            }
                        }
                    },
                    img: {
                        type: AvailableTypes.image,
                        title: 'Картинка'
                    },
                    text: {
                        type: AvailableTypes.editor,
                        title: 'Текст'
                    },
                    list: {
                        title: 'Список',
                        type: AvailableTypes.array,
                        limit: 2,
                        item: {
                            listHeader: {
                                type: AvailableTypes.editor,
                                title: 'Заголовок списка'
                            },
                            listContentElement: {
                                type: AvailableTypes.array,
                                title: 'Элементы списка',
                                item: {
                                    itemName: {
                                        type: AvailableTypes.string,
                                        title: 'Название ссылки'
                                    },
                                    itemLink: {
                                        type: AvailableTypes.string,
                                        title: 'Ссылка'
                                    },
                                    targetBlank: {
                                        title: 'Открывать ссылку в новом окне',
                                        type: AvailableTypes.boolean,
                                        default: false
                                    }
                                }
                            }
                        }
                    },
                    buttonName: {
                        type: AvailableTypes.string,
                        title: 'Текст кнопки',
                        default: 'Наши работы >'
                    },
                    link: {
                        type: AvailableTypes.string,
                        title: 'Ссылка'
                    },
                    targetBlank: {
                        title: 'Открывать ссылку в новом окне',
                        type: AvailableTypes.boolean,
                        default: false
                    }
                } as any,
                default: optionsDefault.TabsSlider
            }
        },
        group: AvailableGroups.Вкладки
    };

    @Component({
        components: {
            ImageComponent,
            SliderWrapper,
            SliderNavBtns,
            SliderBullets,
            BaseTabs,
            Button,
            OptionsFieldsInlineEditor
        },
        directives: { swipe }
    })
    export default class TabsSlider extends PageBlockMixin {
        @Prop() head;
        @Prop() tabs;
        @Prop() component;

        target(tab) {
            return tab.targetBlank ? '_blank' : '_self';
        }
    }
