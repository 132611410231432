
    import { Component, mixins, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import { PageComponent } from '@/store/page';
    import PaletteMixin from '@/mixins/paletteMixin';
    import Button from '@/components/blocks/Button.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import WindowMixin from '@/mixins/windowMixin';
    import Form from '@/components/blocks/Form.vue';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';

    export const meta: ComponentMeta = {
        title: 'Блок вкладок с таблицами',
        options: {
            head: {
                title: 'Заголовок',
                type: AvailableTypes.string,
                default: 'Цены на популярные размеры'
            },
            sizes: {
                title: 'Вкладки',
                type: AvailableTypes.array,
                item: {
                    title: {
                        type: AvailableTypes.string,
                        title: 'Название вкладки'
                    },
                    text: {
                        type: AvailableTypes.editor,
                        title: 'Описание'
                    },
                    buttonText: {
                        type: AvailableTypes.string,
                        title: 'Текст на кнопке'
                    },
                    table: {
                        title: 'Таблица',
                        type: AvailableTypes.editor
                    }
                },
                default: optionsDefault.TabsWithTable
            },
            form: {
                type: AvailableTypes.component,
                componentName: 'Form'
            }
        },
        group: AvailableGroups.Цены
    };

    @Component({
        components: { Form, OptionsFieldsInlineEditor, Button }
    })
    export default class TabsWithTable extends mixins(
        PageBlockMixin,
        PaletteMixin,
        WindowMixin
    ) {
        @Prop() sizes;
        @Prop() form;
        @Prop({ default: 'Заказать расчёт' }) buttonText;
        @Prop() component: PageComponent;

        tableTabIndex = 0;

        changeTableTab(tabindex) {
            this.tableTabIndex = tabindex;
        }

        get existTables() {
            let exist = false;
            if (this.sizes !== undefined) {
                this.sizes.forEach((element, index) => {
                    //console.log(element);
                    if (element.table !== undefined) {
                        exist = true;
                    }
                });
            }
            return exist;
        }
    }
