
    import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
    import { PageComponentOptions } from '@/store/page';
    import { makeid } from '@/components/editor/utils/editor';

    @Component
    export default class SliderWrapper extends Vue {
        @Prop({ type: Array, required: true }) slides!: PageComponentOptions[];
        @Prop(Boolean) hasPreview: boolean;
        @Prop(Boolean) isMultiImage: boolean;
        @Prop({ type: Number, default: 5 }) itemsInPreview!: number;
        @Prop({ type: Number, default: 0 }) startFrom!: number;

        currentIndex = this.startFrom;
        previewImages: any[] = [];
        maxSlidesArrIndex = this.slides.length - 1;
        maxPreviewArrIndex = this.itemsInPreview - 1;
        makeId = makeid;

        created() {
            //if (this.slides.length >= this.itemsInPreview) {
            if (this.hasPreview) {
                const secondIndex = this.getPrevIndex(this.currentIndex);
                const firstIndex = this.getPrevIndex(secondIndex);
                const prevItemIndex = this.getPrevIndex(firstIndex);

                const fourthIndex = this.getNextIndex(this.currentIndex);
                const fifthIndex = this.getNextIndex(fourthIndex);
                const nextItemIndex = this.getNextIndex(fifthIndex);

                this.previewImages = [
                    this.createSlidePreviewObject(prevItemIndex),
                    this.createSlidePreviewObject(firstIndex),
                    this.createSlidePreviewObject(secondIndex),
                    this.createSlidePreviewObject(this.currentIndex),
                    this.createSlidePreviewObject(fourthIndex),
                    this.createSlidePreviewObject(fifthIndex),
                    this.createSlidePreviewObject(nextItemIndex)
                ];
            } else if (this.isMultiImage) {
                this.previewImages.push(
                    this.createSlidePreviewObject(this.maxSlidesArrIndex)
                );

                for (let i = 0; i < this.itemsInPreview; i++) {
                    this.previewImages.push(this.createSlidePreviewObject(i));
                }

                const index = this.getNextIndex(
                    this.previewImages[this.maxPreviewArrIndex + 1].slideIndex
                );

                this.previewImages.push(this.createSlidePreviewObject(index));
            }
            // }
        }

        createSlidePreviewObject(index: number) {
            return {
                src: this.slides[index].src || this.slides[index].img.src,
                title: this.slides[index].title,
                text: this.slides[index].text,
                href: this.slides[index].href,
                slideIndex: index,
                key: this.makeId(5)
            };
        }

        moveArrayForward() {
            for (let i = 0; i < this.maxPreviewArrIndex + 2; i++) {
                this.previewImages[i] = this.previewImages[i + 1];
            }

            const index = this.getNextIndex(
                this.previewImages[this.maxPreviewArrIndex + 2].slideIndex
            );

            this.previewImages[
                this.maxPreviewArrIndex + 2
            ] = this.createSlidePreviewObject(index);
        }

        moveArrayBackwards() {
            for (let i = this.maxPreviewArrIndex + 2; i > 0; i--) {
                this.previewImages[i] = this.previewImages[i - 1];
            }

            const index = this.getPrevIndex(this.previewImages[0].slideIndex);

            this.previewImages[0] = this.createSlidePreviewObject(index);
        }

        @Watch('currentIndex')
        updatePreviewImages(newVal, oldVal) {
            if (this.isMultiImage || this.hasPreview) {
                const steps =
                    (newVal === 0 && oldVal === this.maxSlidesArrIndex) ||
                    (newVal === this.maxSlidesArrIndex && oldVal === 0)
                        ? 1
                        : Math.abs(newVal - oldVal) > 2
                        ? 2
                        : Math.abs(newVal - oldVal); // TODO calculate it dynamically, because we could have sliders where preview could have > than 5 images

                const isForward = (oldVal + steps) % this.slides.length === newVal;

                const moveArrFn = isForward
                    ? this.moveArrayForward
                    : this.moveArrayBackwards;

                for (let i = 0; i < steps; i++) {
                    moveArrFn();
                }
            }
        }

        next(): void {
            this.currentIndex = this.getNextIndex(this.currentIndex);
        }

        prev(): void {
            this.currentIndex = this.getPrevIndex(this.currentIndex);
        }

        getNextIndex(index: number): number {
            return index === this.maxSlidesArrIndex ? 0 : index + 1;
        }

        getPrevIndex(index: number): number {
            return index === 0 ? this.maxSlidesArrIndex : index - 1;
        }

        getClass(index: number): string {
            if (index === this.currentIndex) {
                return 'active';
            }

            if (this.currentIndex === 0 && index !== this.maxSlidesArrIndex) {
                return 'next';
            } else if (this.currentIndex === 0 && index === this.maxSlidesArrIndex) {
                return 'prev';
            } else if (this.currentIndex === this.maxSlidesArrIndex && index !== 0) {
                return 'prev';
            } else if (this.currentIndex === this.maxSlidesArrIndex && index === 0) {
                return 'next';
            } else {
                return index < this.currentIndex ? 'prev' : 'next';
            }
        }

        goToSlide(slideIndex: number): void {
            this.currentIndex = slideIndex;
        }

        render() {
            // @ts-ignore
            return this.$scopedSlots.default({
                slides: this.slides,
                previewImages: this.previewImages,
                prev: this.prev,
                next: this.next,
                getNextIndex: this.getNextIndex,
                getPrevIndex: this.getPrevIndex,
                getClass: this.getClass,
                goToSlide: this.goToSlide,
                currentIndex: this.currentIndex
            });
        }
    }
