var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"slider-block"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"slider-block__title h2 h2_center"},[_c('options-fields-inline-editor',{attrs:{"keyOption":"title","component":_vm.component,"value":_vm.title}})],1),_vm._v(" "),_c('slider-wrapper',{staticClass:"d-none d-lg-block",attrs:{"slides":_vm.items,"items-in-preview":4,"is-multi-image":true},scopedSlots:_vm._u([{key:"default",fn:function({
                    previewImages,
                    prev,
                    next
                }){return [_c('div',{staticClass:"slider-block__slider"},[_c('div',{staticClass:"slider-block__slider__nav"},[_c('button',{staticClass:"slider-block__slider__nav-btn left",on:{"click":prev}}),_vm._v(" "),_c('button',{staticClass:"slider-block__slider__nav-btn right",on:{"click":next}})]),_vm._v(" "),_c('transition-group',{staticClass:"slider-block__slider__content-wrap row",attrs:{"tag":"div"}},_vm._l((previewImages),function({
                                src,
                                medium,
                                title,
                                text,
                                href,
                                targetBlank,
                                key
                            },index){return _c('a',{key:key,staticClass:"slider-block__slider__content col-3",class:{
                                prev: index === 0,
                                active: index > 0 && index < previewImages.length - 1,
                                next: index === previewImages.length - 1,
                                'palette-color-text-hover': href
                            },attrs:{"href":href || undefined,"target":{ '_blank': targetBlank }}},[_c('image-component',{staticClass:"slider-block__slider__img",attrs:{"img":{
                                    src,
                                    medium,
                                    title,
                                    text,
                                    href,
                                    targetBlank,
                                    key
                                }}}),_vm._v(" "),_c('h3',{staticClass:"slider-block__slider__title"},[_vm._v("\n                                "+_vm._s(title)+"\n                            ")]),_vm._v(" "),_c('div',{staticClass:"slider-block__slider__text"},[_vm._v("\n                                "+_vm._s(_vm.striptag(text))+"\n                            ")])],1)}),0)],1)]}}])}),_vm._v(" "),_c('slider-wrapper',{staticClass:"d-lg-none",attrs:{"slides":_vm.items,"items-in-preview":3,"is-multi-image":true},scopedSlots:_vm._u([{key:"default",fn:function({
                    previewImages,
                    prev,
                    next
                }){return [_c('div',{staticClass:"slider-block__slider"},[_c('transition-group',{staticClass:"slider-block__slider__content-wrap",attrs:{"tag":"div"}},_vm._l((previewImages),function({
                                src,
                                title,
                                text,
                                href,
                                targetBlank,
                                key,
                                medium
                            },index){return _c('a',{directives:[{name:"swipe",rawName:"v-swipe:left",value:(next),expression:"next",arg:"left"},{name:"swipe",rawName:"v-swipe:right",value:(prev),expression:"prev",arg:"right"}],key:key,staticClass:"slider-block__slider__content",class:{
                                prev: index === 0,
                                active: index === 1,
                                next: index === 2,
                                'next unseen': index > 2,
                                'palette-color-text-hover': href
                            },attrs:{"target":{ '_blank': targetBlank },"href":href || undefined}},[_c('image-component',{staticClass:"slider-block__slider__img",attrs:{"img":{
                                    src,
                                    title,
                                    text,
                                    href,
                                    targetBlank,
                                    key,
                                    medium
                                }}}),_vm._v(" "),_c('h3',{staticClass:"slider-block__slider__title"},[_vm._v("\n                                "+_vm._s(title)+"\n                            ")]),_vm._v(" "),_c('div',{staticClass:"slider-block__slider__text"},[_vm._v("\n                                "+_vm._s(_vm.striptag(text))+"\n                            ")])],1)}),0)],1)]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }