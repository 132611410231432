
    import { Component, Prop, mixins } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import InlineSlider from '@/components/blocks/InlineSlider.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import SliderWrapper from '@/components/blocks/SliderWrapper.vue';
    import swipe from '@/directives/swipe';
    import striptags from 'striptags';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import ImageMixin from '@/mixins/imageMixin';

    export interface Item {
        img: string;
        title?: string;
        src: string;
    }

    export const meta = {
        title: 'Наши работы слайдер',
        options: {
            title: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                default: 'Наши работы'
            },
            items: {
                title: 'Работы',
                type: AvailableTypes.array,
                item: {
                    img: { type: AvailableTypes.image, title: 'Картинка' },
                    title: {
                        type: AvailableTypes.string,
                        title: 'Заголовок'
                    },
                    href: {
                        type: AvailableTypes.string,
                        title: 'Ссылка',
                        default: ''
                    },
                    targetBlank: {
                        title: 'Открывать ссылку в новом окне',
                        type: AvailableTypes.boolean,
                        default: false
                    },
                    text: {
                        type: AvailableTypes.editor,
                        title: 'Текст'
                    }
                },
                default: optionsDefault.SliderBlock
            }
        },
        group: AvailableGroups.Слайдер
    };

    @Component({
        components: {
            ImageComponent,
            OptionsFieldsInlineEditor,
            InlineSlider,
            SliderWrapper
        },
        directives: { swipe }
    })
    export default class Products extends mixins(PageBlockMixin, ImageMixin) {
        @Prop(Array) items: Item[];
        @Prop() title;
        @Prop() component;

        striptag = striptags;
    }
