var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"tabs-slider"},[_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"lg":"10","offset-lg":"1"}},[_c('h2',{staticClass:"h2 tabs-slider__header"},[_c('options-fields-inline-editor',{attrs:{"keyOption":"head","component":_vm.component,"value":_vm.head}})],1)])],1),_vm._v(" "),_c('b-row',{staticClass:"tabs-slider__wrap"},[_c('b-col',{attrs:{"lg":"10","offset-lg":"1"}},[_c('b-tabs',{attrs:{"nav-class":"tab-nav-block justify-content-xl-start"}},_vm._l((_vm.tabs),function(tab,index){return _c('b-tab',{key:index,attrs:{"index":index,"title":tab.tabTitle}},[_c('div',{staticClass:"tabs-slider__container"},[_c('b-row',[_c('b-col',{staticClass:"no-padding-right order-lg-first order-last",attrs:{"cols":"11","lg":"5","md":"8"}},[_c('div',{staticClass:"tabs-slider__wrap"},[_c('div',{staticClass:"tabs-slider__title tabs-slider__title--desktop"},[_vm._v("\n                                            "+_vm._s(tab.tabTitle)+"\n                                        ")]),_vm._v(" "),_c('div',{staticClass:"tabs-slider__text"},[_c('options-fields-inline-editor',{attrs:{"keyOption":`tabs.${index}.text`,"component":_vm.component,"value":tab.text}})],1),_vm._v(" "),_c('div',{staticClass:"tabs-slider__row"},_vm._l((tab.list),function(columns,i){return _c('div',{key:i,staticClass:"tabs-slider__col"},[_c('div',{staticClass:"tabs-slider__list__header"},[_c('options-fields-inline-editor',{attrs:{"keyOption":`tabs.${index}.list.${i}.listHeader`,"component":_vm.component,"value":columns.listHeader}})],1),_vm._v(" "),_vm._l((columns.listContentElement),function(row,index){return _c('div',{key:index,staticClass:"tabs-slider__list__link"},[_c('a',{attrs:{"href":row.itemLink,"target":{
                                                            '_blank': row.targetBlank
                                                        }}},[_vm._v("\n                                                        "+_vm._s(row.itemName)+"\n                                                    ")])])})],2)}),0),_vm._v(" "),_c('Button',{attrs:{"link":tab.link,"text":tab.buttonName,"target-blank":_vm.target(tab)}})],1)]),_vm._v(" "),_c('b-col',{staticClass:"col-12 col-lg-6 col-md-8 offset-lg-1 order-lg-last order-first text-md-left text-sm-right"},[_c('div',{staticClass:"tabs-slider__title tabs-slider__title--mobile"},[_vm._v("\n                                        "+_vm._s(tab.tabTitle)+"\n                                    ")]),_vm._v(" "),(tab.sliderImg)?_c('SliderWrapper',{attrs:{"slides":tab.sliderImg,"has-preview":false,"is-multi-image":false},scopedSlots:_vm._u([{key:"default",fn:function({
                                            slides,
                                            prev,
                                            next,
                                            currentIndex,
                                            getClass
                                        }){return [_c('div',[_c('div',{staticClass:"tabs-slider__slider"},[_c('div',{staticClass:"tabs-slider__slider__img-wrap"},_vm._l((slides),function({ img },index){return _c('div',{directives:[{name:"swipe",rawName:"v-swipe:left",value:(next),expression:"next",arg:"left"},{name:"swipe",rawName:"v-swipe:right",value:(prev),expression:"prev",arg:"right"}],key:`slide-${index}`,staticClass:"tabs-slider__slider__slide",class:getClass(index)},[_c('image-component',{staticClass:"tabs-slider__slider__img",attrs:{"img":img}})],1)}),0),_vm._v(" "),_c('SliderNavBtns',{staticClass:"d-none d-lg-block",on:{"prev":prev,"next":next}})],1),_vm._v(" "),_c('SliderBullets',{staticClass:"tabs-slider__slider__bullets d-lg-none",attrs:{"slides-amount":slides.length,"current":currentIndex}})],1)]}}],null,true)}):_vm._e(),_vm._v(" "),(tab.img)?_c('image-component',{staticClass:"tabs-slider__image",attrs:{"img":tab.img}}):_vm._e()],1)],1)],1)])}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }