
    import { Component, Prop, mixins } from 'nuxt-property-decorator';
    import ImageMixin from '@/mixins/imageMixin';

    @Component
    export default class ResultModal extends mixins(ImageMixin) {
        @Prop() name;
        @Prop() isSmall;
        @Prop() id;
        @Prop() image;
        @Prop() text;

        mounted() {
            this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
                let evt = document.createEvent('UIEvents');
                // @ts-ignore
                evt.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(evt);
            });
        }

        get classDialog() {
            if (this.isSmall) {
                return 'result-modal__wrap-small';
            }

            return 'result-modal__wrap';
        }

        get resultText() {
            return this.text ? this.text : 'Спасибо за обращение в нашу компанию!';
        }
    }
