
    import { Vue, Component, Prop } from 'nuxt-property-decorator';
    import { AvailableGroups, AvailableTypes } from '../editor/config/types';
    import BaseSlider from '@/components/common/BaseSlider.vue';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';

    export const meta = {
        title: 'Простой слайдер',
        options: {
            items: {
                title: 'Слайды',
                type: AvailableTypes.array,
                item: {
                    img: { type: AvailableTypes.image, title: 'Картинка' },
                    text: {
                        type: AvailableTypes.string,
                        title: 'Текст'
                    }
                },
                default: [
                    {
                        img: { src: '/placeholder-600x400.png' },
                        text: `Когда раскрой делает один подрядчик, а печатает другой, детали разных размеров могут перепутать. `
                    }
                ]
            },
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };
    @Component({
        components: {
            BlogComponentWrapper,
            BaseSlider
        }
    })
    export default class SimpleSlider extends Vue {
        @Prop() items: any[];
        @Prop() additionalComponent: any[];
    }
