
    import { Component, Prop, mixins } from 'nuxt-property-decorator';
    import InlineSlider from '@/components/blocks/InlineSlider.vue';
    import ImageMixin from '@/mixins/imageMixin';
    import windowMixin from '@/mixins/windowMixin';

    @Component({
        components: {
            InlineSlider
        }
    })
    export default class RelatedSliders extends mixins(ImageMixin, windowMixin) {
        @Prop() images;
        @Prop({ default: 0 }) startSlide = 0;
        relatedSliderId = 'bigSlider' + this.RandomInt;
        relatedNavId = 'smallSlider' + this.RandomInt;
        navClass = 'nav' + this.RandomInt;
        activeIndex = this.startSlide;

        get RandomInt() {
            return Math.floor(Math.random() * (1000 - 1)) + 1;
        }

        activeSlide(currentSlide) {
            this.activeIndex = currentSlide ? currentSlide : 0;
        }
    }
