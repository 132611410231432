var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.images.length > 0)?_c('div',[_c('section',{staticClass:"our-works-example"},[_c('div',{staticClass:"container"},[(_vm.head)?_c('h2',{staticClass:"h2 h2_center our-works-example__title"},[_c('options-fields-inline-editor',{attrs:{"keyOption":"head","component":_vm.component,"value":_vm.head}})],1):_vm._e(),_vm._v(" "),_c('SliderWrapper',{attrs:{"slides":_vm.images,"has-preview":true},scopedSlots:_vm._u([{key:"default",fn:function({
                        slides,
                        previewImages,
                        prev,
                        next,
                        getClass,
                        goToSlide,
                        currentIndex
                    }){return [_c('b-row',[_c('b-col',{staticClass:"col-lg-10 offset-lg-1"},[_c('div',{staticClass:"our-works-example__slider"},[_c('div',{staticClass:"our-works-example__slider__content-wrap"},_vm._l((slides),function(img,index){return _c('div',{key:index},[_c('div',{directives:[{name:"swipe",rawName:"v-swipe:left",value:(next),expression:"next",arg:"left"},{name:"swipe",rawName:"v-swipe:right",value:(prev),expression:"prev",arg:"right"}],staticClass:"our-works-example__slider__content",class:getClass(index)},[_c('image-component',{staticClass:"our-works-example__slider__img",attrs:{"img":img}}),_vm._v(" "),_c('SliderBullets',{staticClass:"our-works-example__slider__bullets-wrap d-lg-none",attrs:{"current":currentIndex,"slides-amount":slides.length}})],1)])}),0),_vm._v(" "),_c('SliderNavBtns',{staticClass:"d-none d-lg-flex",on:{"prev":prev,"next":next}})],1),_vm._v(" "),_c('div',{staticClass:"our-works-example__slide-count d-none d-lg-block"},[_vm._v("\n                                "+_vm._s(currentIndex + 1)+" /\n                                "+_vm._s(slides.length)+"\n                            ")]),_vm._v(" "),_c('transition-group',{staticClass:"our-works-example__preview__content-wrap row d-none d-lg-flex",attrs:{"tag":"div","name":"preview"}},_vm._l((previewImages),function({ src, medium, slideIndex, key },index){return _c('div',{key:`item-${key}`,staticClass:"our-works-example__preview__content",class:[
                                        `transform-${index}`,
                                        {
                                            prev: index === 0,
                                            next: index === previewImages.length - 1,
                                            active:
                                                index > 0 &&
                                                index < previewImages.length - 1
                                        }
                                    ],on:{"click":function($event){return goToSlide(slideIndex)}}},[_c('image-component',{staticClass:"our-works-example__preview__img",attrs:{"img":{ src, medium, slideIndex, key }}})],1)}),0)],1)],1)]}}],null,false,2670324461)})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }