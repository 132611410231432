
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';

    interface TextBlockMeta extends ComponentMeta {}

    export const meta: TextBlockMeta = {
        title: 'Заголовок',
        options: {
            text: {
                title: 'Текст',
                type: AvailableTypes.editor,
                default:
                    'Мы подходим не всем, но можем быть полезны. \n' +
                    'Читайте в блоге статьи о бизнесе, новости о бухгалтерии и советы для предпринимателей.'
            }
        },
        group: AvailableGroups.Тексты
    };
    @Component({
        components: { OptionsFieldsInlineEditor }
    })
    export default class TextBlock extends Vue {
        @Prop() text;
        @Prop() component;
    }
