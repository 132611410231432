
    import { Component, Prop, mixins } from 'nuxt-property-decorator';
    import ModelsImages from '@/components/models/ModelsImages.vue';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import { PageComponent } from '@/store/page';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import BaseTabs from '@/components/blocks/base/BaseTabs.vue';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';

    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export interface ImageTab {
        title: string;
        images: Image[];
        text: string;
    }

    export interface Image {
        src: string;
        title?: string;
        alt?: string;
    }

    export const meta = {
        title: 'Вкладки с изображениями и текстом',
        options: {
            head: {
                type: AvailableTypes.editor,
                title: 'Заголовок',
                default: 'Модели футболок'
            },
            text: {
                type: AvailableTypes.editor,
                title: 'Текст',
                default:
                    'На производстве есть базовый ассортимент отработанных лекал.' +
                    'Кроме классических моделей предлагаем оверсайз, реглан, объемные и облегающие футболки. Лекала мужские и женские, полный размерный ряд.'
            },
            tabs: {
                type: AvailableTypes.array,
                title: 'Табы',
                item: {
                    title: {
                        type: AvailableTypes.string,
                        title: 'Заголовок'
                    },
                    images: {
                        type: AvailableTypes.arrayOfType,
                        title: 'Картинки',
                        item: {
                            type: AvailableTypes.image,
                            title: 'Картинка'
                        }
                    },
                    text: {
                        type: AvailableTypes.editor,
                        title: 'Текст'
                    }
                },
                default: optionsDefault.Models
            }
        },
        group: AvailableGroups.Вкладки
    };

    @Component({
        components: { BaseTabs, OptionsFieldsInlineEditor, ModelsImages }
    })
    export default class TabsWithImagesAndText extends mixins(PageBlockMixin) {
        @Prop() tabs: ImageTab[];
        @Prop() test;
        @Prop() component: PageComponent;
        @Prop() index;
    }
