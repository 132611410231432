
    import { Component, Prop } from 'nuxt-property-decorator';
    import DetectScrollDirectionMixin from '@/mixins/detectScrollDirectionMixin';

    @Component
    export default class ScrollLink extends DetectScrollDirectionMixin {
        @Prop(String) href: string;

        mounted() {
            this.detectScrollDirection();
        }

        scroll() {
            // @ts-ignore
            document.querySelector('body').scrollIntoView({ behavior: 'smooth' });
        }
    }
